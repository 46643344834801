<template>
  <b-container fluid>
    <b-card>
      <b-form>
        <b-row>
          <b-col md="5">
            <b-form-group label="Data Inicial">
              <b-form-input v-model="props.initialDate" type="date" @input="resetError" />
            </b-form-group>
          </b-col>

          <b-col md="5">
            <b-form-group label="Data Final">
              <b-form-input v-model="props.finalDate" type="date" @input="resetError" />
            </b-form-group>
          </b-col>

          <b-col md="2" class="d-flex align-items-end">
            <b-button
              variant="primary"
              style="width: 150px; height: 40px; margin-bottom: 13px"
              @click="handleSearch"
              :disabled="loading || dateError"
            >
              Buscar
            </b-button>
          </b-col>
        </b-row>

        <p v-if="dateError"  class="text-center text-danger">
          O período digitado é inválido. A data inicial não pode ser maior que a data final.
          
        </p>
      </b-form>
    </b-card>

    <conciliation-values
    @loadingChanged="handleLoadingChanged"
      ref="ConciliationValues"
      :initialDate="props.initialDate"
      :finalDate="props.finalDate"
    />

    <table-events
      ref="tableEvents"
      :initialDate="props.initialDate"
      :finalDate="props.finalDate"
    />
    <documents-conciliation
      ref="DocumentsConciliation"
      :initialDate="props.initialDate"
      :finalDate="props.finalDate"
    />
  </b-container>
</template>

<script>
import { getCurrentDate } from "@/utils/date/current";
import DocumentsConciliation from "./DocumentsConciliation.vue";
import ConciliationValues from "./ConciliationValues.vue";
import TableEvents from "./TableEvents.vue";
import { BContainer, BCard, BForm, BFormInput, BRow, BCol, BFormGroup, BButton, BAlert } from "bootstrap-vue";

export default {
  data() {
    return {
      props: {
        initialDate: getCurrentDate(),
        finalDate: getCurrentDate(),
      },
      dateError: false,
      loading: false,
    };
  },
  components: {BContainer, BCard, BForm, BFormInput,BRow,BCol,BFormGroup, BButton, BAlert, ConciliationValues, TableEvents, DocumentsConciliation,},
  
  methods: {
     resetError() {
      this.dateError = false;
    },
    handleSearch() {
      this.loading = true;

      const initial = new Date(this.props.initialDate);
      const final = new Date(this.props.finalDate);

      if (initial > final) {
        this.loading = false;
        this.dateError = true;
        return; 
      } else {
        this.dateError = false;
      }

      if (this.$refs.tableEvents) {
        this.$refs.tableEvents.fetchData();
      } else {
        console.error("Componente TableEvents não encontrado.");
      }
      if (this.$refs.ConciliationValues) {
        this.$refs.ConciliationValues.fetchData();
      } else {
        console.error("Componente ConciliationValues não encontrado.");
      }
      if (this.$refs.DocumentsConciliation) {
        this.$refs.DocumentsConciliation.fetchData();
      } else {
        console.error("Componente DocumentsConciliation não encontrado.");
      }
    },
    
    handleLoadingChanged(newLoadingState) {
      this.loading = newLoadingState;

    },
  },
};
</script>
