<template>
  <b-card class="mt-4">
    <b-form>
      <template v-if="loading">
        <p
          class="d-flex justify-content-end align-items-center text-right loading-message"
        >
          Carregando dados. Aguarde...
        </p>
        <div class="spinner-border text-primary spinner" role="status"></div>
      </template>
      <template v-if="fieldsEmptyMessage && !loading">
        <p
          class="d-flex justify-content-end align-items-center text-right no-data-message"
        >
          {{ fieldsEmptyMessage }}
        </p>
      </template>
      <p
        v-if="!loading && noDataMessage && !fieldsEmptyMessage"
        class="text-center text-danger no-data-message"
      >
        {{ noDataMessage }}
      </p>
      <b-row class="align-items-center">
        <b-col md="2">
          <b-form-group>
            <b-form-checkbox
              v-model="checkboxSelected1"
              style="margin-top: 30px; margin-left: 50px"
              @change="checkFields()"
              :disabled="!!fieldsEmptyMessage"
            >
              Confirmar
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col sm="8" md="3">
          <b-form-group label="Valor Bruto">
            <b-form-input
              v-model="amount"
              type="text"
              :disabled="true"
              :class="{
                'bg-success': checkboxSelected1,
                'text-white': checkboxSelected1,
              }"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="8"
          md="3"
          class="d-flex align-items-end update-button-container"
        >
          <b-button
            variant="primary"
            style="width: 100%; height: 40px; margin-top: 10px"
            @click="executeTask(tasks[0])"
            :disabled="checkboxSelected1"
          >
            Atualizar Transação
          </b-button>
        </b-col>
      </b-row>

      <b-row class="align-items-center">
        <b-col md="2">
          <b-form-group>
            <b-form-checkbox
              v-model="checkboxSelected2"
              style="margin-top: 30px; margin-left: 50px"
              @change="checkFields()"
              :disabled="!!fieldsEmptyMessage"
            >
              Confirmar
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col sm="8" md="3">
          <b-form-group label="Valor Pago">
            <b-form-input
              v-model="paymented"
              :disabled="true"
              type="text"
              :class="{
                'bg-success': checkboxSelected2,
                'text-white': checkboxSelected2,
              }"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="8"
          md="3"
          class="d-flex align-items-end update-button-container"
        >
          <b-button
            variant="primary"
            style="width: 100%; height: 40px; margin-top: 10px"
            @click="executeTask(tasks[1])"
            :disabled="checkboxSelected2"
          >
            Atualizar Pagamento
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="2">
          <b-form-group>
            <b-form-checkbox
              v-model="checkboxSelected2"
              style="margin-top: 30px; margin-left: 50px"
              @change="checkFields()"
              :disabled="!!fieldsEmptyMessage"
            >
              Confirmar
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col sm="8" md="3">
          <b-form-group label="Valor Antecipado">
            <b-form-input
              v-model="antecipated"
              :disabled="true"
              type="text"
              :class="{
                'bg-success': checkboxSelected2,
                'text-white': checkboxSelected2,
              }"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="8"
          md="3"
          class="d-flex align-items-center update-button-container"
        >
          <b-button
            variant="primary"
            style="width: 100%; height: 40px; margin-top: 10px"
            @click="executeTask(tasks[1])"
            :disabled="checkboxSelected2"
          >
            Atualizar Pagamento
          </b-button>
        </b-col>
      </b-row>

      <b-row class="align-items-center">
        <b-col md="2">
          <b-form-group>
            <b-form-checkbox
              v-model="checkboxSelected3"
              style="margin-top: 30px; margin-left: 50px"
              @change="checkFields()"
              :disabled="!!fieldsEmptyMessage"
            >
              Confirmar
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col sm="8" md="3">
          <b-form-group label="Valor Liquidado">
            <b-form-input
              v-model="liquidatedValue"
              type="text"
              :disabled="true"
              :class="{
                'bg-success': checkboxSelected3,
                'text-white': checkboxSelected3,
              }"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="8"
          md="3"
          class="d-flex align-items-end update-button-container"
        >
          <b-button
            variant="primary"
            style="width: 100%; height: 40px; margin-top: 10px"
            @click="executeTask(tasks[2])"
            :disabled="checkboxSelected3"
          >
            Atualizar Liquidações
          </b-button>
        </b-col>
      </b-row>

      <b-row
        class="justify-content-center align-items-center"
        style="margin-top: 60px"
      >
        <b-col sm="6" md="3" class="d-flex justify-content-center mb-3">
          <b-button
            style="width: 100%; height: 40px"
            variant="primary"
            @click="executeTask(tasks[3])"
            :disabled="
              loading ||
              !checkboxSelected1 ||
              !checkboxSelected2 ||
              !checkboxSelected3
            "
          >
            Arquivos Equals
          </b-button>
        </b-col>

        <b-col sm="6" md="3" class="d-flex justify-content-center mb-3">
          <b-button
            style="width: 100%; height: 40px"
            variant="primary"
            @click="executeTask(tasks[4])"
            :disabled="
              loading ||
              !checkboxSelected1 ||
              !checkboxSelected2 ||
              !checkboxSelected3
            "
          >
            Arquivos Continental
          </b-button>
        </b-col>

        <b-col sm="6" md="3" class="d-flex justify-content-center mb-3">
          <b-button
            style="width: 100%; height: 40px"
            variant="primary"
            @click="executeTask(tasks[5])"
            :disabled="
              loading ||
              !checkboxSelected1 ||
              !checkboxSelected2 ||
              !checkboxSelected3
            "
          >
            Arquivos Boa Vista
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import axios from "axios";
import { getCurrentDate } from "@/utils/date/current";
import { DateTime } from "luxon";
import {
  BContainer,
  BCard,
  BForm,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import TaskService from "@/service/tasks";
import { callLoading } from "@/service/utilities/index";
import UserService from "@/service/user/user";

export default {
  props: {
    initialDate: { type: String, required: true },
    finalDate: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      checkboxSelected1: false,
      checkboxSelected2: false,
      checkboxSelected3: false,
      checkboxSelected4: false,
      noDataMessage: "Não há resultado para essa data.",
      fieldsEmptyMessage: "",
      amount: "",
      paymented: "",
      antecipated: "",
      liquidatedValue: "",
      tasks: [
        { title: "Atualizar Transações", path: "/checkingTransactions " },
        { title: "Atualizar Pagamentos", path: "/upload/updateInstallments" },
        { title: "Atualizar Liquidações", path: "/liquidation-veripag" },
        { title: "Arquivos Equals", path: "/sftp-move-edi-equals " },
        { title: "Arquivos Continental", path: "/sftp-move-sales" },
        { title: "Arquivo BoaVista", path: "/send-eextract" },
      ],
    };
  },
  components: {
    BContainer,
    BCard,
    BForm,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormCheckbox,
  },

  methods: {
    async fetchData() {
      this.clearFields();
      this.loading = true;

      if (!this.initialDate || !this.finalDate) {
        console.log("Datas não definidas");
        return;
      }
      try {
        const BASE_URL = process.env.VUE_APP_BASE_URL_THOTH;
        const API_KEY = process.env.VUE_APP_API_KEY_THOTH;

        const startDate = DateTime.fromISO(this.initialDate).toFormat(
          "yyyy-MM-dd"
        );
        const endDate = DateTime.fromISO(this.finalDate).toFormat("yyyy-MM-dd");

        const response = await axios.get(`${BASE_URL}amount-transacted/get`, {
          params: { initialDate: startDate, finalDate: endDate },
          headers: { "Api-Key": API_KEY },
        });

        if (response.status == 200) {
          const data = response.data.values;
          this.amount = (parseFloat(data.amount) || 0).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          this.paymented = (parseFloat(data.paymented) || 0).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          );
          this.antecipated = (parseFloat(data.antecipated) || 0).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          );
          this.liquidatedValue = (
            parseFloat(data.liquidatedValue) || 0
          ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
          this.noDataMessage = "";
          this.fieldsEmptyMessage = "";
        } else {
          this.noDataMessage = "Não há resultado para essa data.";
          this.fieldsEmptyMessage = "";
          this.loading = false;
          this.clearFields();
        }
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
        this.noDataMessage = "Erro ao buscar dados.";
        this.clearFields();
      } finally {
        this.loading = false;
        this.$emit("loadingChanged", this.loading);
      }
    },

    async executeTask(task) {
      const isConfirmed = await this.confirmAnAction(
        `Deseja realmente rodar a tarefa ${task.title}?`
      );

      if (!isConfirmed) return;

      TaskService.executeTask({
        url: task.path,
        endDate: this.finalDate,
        initialDate: this.initialDate,
      }).then(({ status }) => {
        if (status === 200) {
          this.toast(`'${task.title}' Foi Concluído`, "CheckIcon", "success");
        } else {
          this.toast(`Erro ao Executar ${task.title}`, "XIcon", "danger");
        }
      });

      this.modal(
        `'${task.title}' está sendo executado, você será notificado quando terminar`
      );
      console.log(sessionStorage.getItem("token"));
      const { data, status } = await UserService.me();
      const userName = data.user.name;

      const BASE_URL = process.env.VUE_APP_BASE_URL_THOTH;
      const API_KEY = process.env.VUE_APP_API_KEY_THOTH;
      let body = {
        taskName: task.title,
        username: userName,
        executionDate: getCurrentDate(),
      };
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${BASE_URL}storeEvents`,
        headers: {
          "Content-Type": "application/json",
          "Api-Key": API_KEY,
        },
        data: body,
      };
      const responseEvent = await axios.request(config);
      console.log(responseEvent);

      this.callLoading(false);
    },
    checkFields() {
      if (
        !this.amount ||
        !this.paymented ||
        !this.antecipated ||
        !this.liquidatedValue
      ) {
        this.fieldsEmptyMessage = "Faça a consulta antes de confirmar.";
      } else {
        this.fieldsEmptyMessage = "";
      }
    },
    clearFields() {
      this.amount = "";
      this.paymented = "";
      this.antecipated = "";
      this.liquidatedValue = "";
    },
  },
};
</script>
<style scoped >
.loading-message,
.no-data-message {
  position: absolute;
  top: 35%;
  margin-right: 8%;
  transform: translateY(-50%);
  right: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.spinner {
  position: absolute;
  right: 10px;
  top: 40%;
  margin-right: 13.3%;
  margin-left: 10px;
}

@media (max-width: 1130px) {
  .spinner {
    margin-right: 16%;
  }
}
@media (max-width: 768px) {
  .loading-message,
  .no-data-message {
    position: relative;
    top: auto;
    transform: none;
    margin-right: 0;
    right: auto;
  }

  .update-button-container,
  .b-col {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-direction: column;
  }

  .update-button-container .b-button {
    width: 100% !important;
    margin-top: 10px;
  }

  .update-button-container .b-col {
    margin-bottom: 15px;
  }
}
</style>