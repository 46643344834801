<template>
  <b-card>
    <h2 class="text-center mb-4">Arquivos de Conciliação</h2>

    <b-row class="mb-3">
      <b-col md="4">
        <b-form-group label="Selecione o tipo dos dados">
          <b-form-select
            v-model="selectedOption"
            :options="options"
            @change="resetState"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card>
      <div class="table-responsive">
        <template v-if="loading">
          <p class="text-center">Carregando...</p>
        </template>
        <template v-else-if="tableData.length">
          <b-table
            :items="paginatedData"
            :fields="tableFields"
            class="text-center"
          >
            <template
              v-for="field in tableFields"
              :slot="`cell(${field.key})`"
              slot-scope="data"
            >
              {{ data.item[field.key] }}
            </template>

            <template v-slot:cell(actions)="{ item }">
              <feather-icon
                icon="DownloadIcon"
                @click="handleDownload(item)"
                style="cursor: pointer"
              />
            </template>
          </b-table>
        </template>
        <p v-else class="text-center text-danger">
          Não há resultado para essa data.
        </p>
      </div>

      <b-pagination
        v-if="tableData.length"
        v-model="currentPage"
        :total-rows="tableData.length"
        :per-page="itemsPerPage"
        align="center"
        size="md"
        class="mt-3"
      >
      </b-pagination>
    </b-card>
  </b-card>
</template>
  
  <script>
import axios from "axios";
import { DateTime } from "luxon";
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
  },

  props: {
    initialDate: { type: String, required: true },
    finalDate: { type: String, required: true },
  },

  data() {
    return {
      selectedOption: "",
      options: [
        { value: "showEquals", text: "Equals" },
        { value: "showBoaVista", text: "Boa Vista" },
        { value: "fileConciliation", text: "Continental" },
      ],
      tableFields: [],
      tableData: [],
      currentPage: 1,
      itemsPerPage: 9,
      loading: false,
      fieldsConfig: {
        showEquals: [
          { key: "ec", label: "EC" },
          { key: "stablishment", label: "Estabelecimento" },
          { key: "file_name", label: "Nome do Arquivo" },
          { key: "cnpj", label: "CNPJ" },
          { key: "date", label: "Data" },
          { key: "actions", label: "Baixar" },
        ],
        showBoaVista: [
          { key: "ec", label: "EC" },
          { key: "stablishment", label: "Estabelecimento" },
          { key: "file_name", label: "Nome do Arquivo" },
          { key: "cnpj", label: "CNPJ" },
          { key: "date", label: "Data" },
          { key: "actions", label: "Baixar" },
        ],
        fileConciliation: [
          { key: "ec", label: "EC" },
          { key: "stablishment", label: "Estabelecimento" },
          { key: "file_name", label: "Nome do Arquivo" },
          { key: "cnpj", label: "CNPJ" },
          { key: "date", label: "Data" },
          { key: "actions", label: "Baixar" },
        ],
      },
    };
  },

  computed: {
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.tableData.slice(startIndex, endIndex);
    },
  },

  methods: {
    async fetchData() {
      if (!this.selectedOption || !this.initialDate || !this.finalDate) return;

      this.loading = true;

      try {
        const BASE_URL = process.env.VUE_APP_BASE_URL_THOTH;
        const API_KEY = process.env.VUE_APP_API_KEY_THOTH;

        const formattedInitialDate = DateTime.fromISO(this.initialDate)
          .startOf("day")
          .toUTC()
          .toISO();
        const formattedFinalDate = DateTime.fromISO(this.finalDate)
          .endOf("day")
          .toUTC()
          .toISO();

        const response = await axios.get(`${BASE_URL}fileConciliation`, {
          params: {
            initialDate: formattedInitialDate,
            finalDate: formattedFinalDate,
          },
          headers: { "Api-Key": API_KEY },
        });

        if (response.data && response.data.length > 0) {
          this.processData(response.data);
        } else {
          this.clearData();
        }
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
        this.clearData();
      } finally {
        this.loading = false;
      }
    },

    processData(data) {
      let filteredData;

      if (this.selectedOption === "fileConciliation") {
        filteredData = data.filter(
          (row) => row.conciliation_type === "Vendas e pagamentos"
        );
      } else if (this.selectedOption === "showEquals") {
        filteredData = data.filter((row) => row.conciliation_type === "Equals");
      } else if (this.selectedOption === "showBoaVista") {
        filteredData = data.filter(
          (row) => row.conciliation_type === "Boa Vista"
        );
      } else {
        filteredData = data;
      }

      this.tableFields = this.fieldsConfig[this.selectedOption] || [];

      filteredData = filteredData.sort((a, b) => b.date.localeCompare(a.date));

      this.tableData = filteredData.map((row) => {
        const newRow = { ...row };

        if ("date" in newRow) {
          newRow["date"] = this.formatDate(newRow["date"]);
        }
        if ("cnpj" in newRow) {
          newRow["cnpj"] = this.formatCNPJ(newRow["cnpj"]);
        }

        return newRow;
      });
    },

    clearData() {
      this.tableFields = [];
      this.tableData = [];
    },

    formatDate(value) {
      if (!value) return "";
      try {
        const date = new Date(value);
        if (isNaN(date.getTime())) return value;

        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
      } catch (error) {
        console.error("Erro ao formatar data:", error);
        return value;
      }
    },
    formatCNPJ(cnpj) {
      if (!cnpj) return cnpj;

      cnpj = cnpj.replace(/\D/g, "");
      if (cnpj.length === 14) {
        return cnpj.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      }

      return cnpj;
    },
    resetState() {
      this.currentPage = 1;
      this.clearData();
      this.fetchData();
    },
    async handleDownload(item) {
  try {
    const fileName = item.file_name; 
    const bucket = "conciliations-soulpay"; 

    const BASE_URL = process.env.VUE_APP_BASE_URL_THOTH;

    const downloadUrl = `${BASE_URL}download-file-s3`;

    const data = {bucket: bucket, file_name: fileName,};
    const API_KEY = process.env.VUE_APP_API_KEY_THOTH;

    const response = await axios.post(downloadUrl, data, {
      headers: {'Api-Key': API_KEY,},
    });

    if (response.data) {
      const downloadLink = response.data; 

      window.location.href = downloadLink; 
    } else {
      console.error("Erro: Não foi possível obter a URL de download.");
    }
  } catch (error) {
    console.error("Erro ao tentar fazer o download:", error);
  }
}

  },

  watch: {
    selectedOption: {
      immediate: true,
      handler() {
        this.resetState();
      },
    },
    initialDate: {
      immediate: true,
      handler() {
        this.resetState();
      },
    },
    finalDate: {
      immediate: true,
      handler() {
        this.resetState();
      },
    },
  },

  beforeMount() {
    this.selectedOption = "showEquals";
  },
};
</script>

  <style scoped>
.mb-3 {
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-danger {
  color: red;
}

.table-responsive {
  overflow-x: auto;
}
</style>
  