<template>
  
    <b-card>
      
      <h2 class="text-center mb-4">Eventos</h2>
      <b-table
        v-if="events.length > 0"
        
        :per-page="perPage"
        :current-page="currentPage"
        :items="events"
        striped
        hover
        responsive
        :busy="isLoading"
        class="text-center "
        :fields="tableFields"
      >
      </b-table>

      <p v-else class="text-center text-danger">
        Não há resultado para essa data.
      </p>

      <b-pagination
        v-if="!isLoading"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="md"
        class="mt-3"
      />
    </b-card>
</template>

<script>
import { BContainer, BTable, BPagination, BCard } from "bootstrap-vue";
import axios from "axios";
import { DateTime } from "luxon";

export default {
  props: {
    initialDate: { type: String, required: true },
    finalDate: { type: String, required: true },
  },
  components: {BContainer, BTable, BPagination, BCard,},
  data() {
    return {
      perPage: 8,
      totalRows: null,
      currentPage: 1,
      isLoading: false,
      tableFields: [
        { key: "user", label: "Responsável" },
        { key: "name", label: "Evento" },
        { key: "execution_date", label: "Data/Hora" },
      ],
      events: [],
    };
  },
  watch: {
    initialDate(newVal, oldVal) {
      if (newVal !== oldVal) this.fetchData();
    },
    finalDate(newVal, oldVal) {
      if (newVal !== oldVal) this.fetchData();
    },
  },
  methods: {
    async fetchData() {
    
      if (!this.initialDate || !this.finalDate) {
        console.log("Datas não definidas");
        return;
      }

      this.isLoading = true;

      try {
        const BASE_URL = process.env.VUE_APP_BASE_URL_THOTH;
        const API_KEY = process.env.VUE_APP_API_KEY_THOTH;

        const response = await axios.get(`${BASE_URL}showEvents`, {
          headers: { "Api-Key": API_KEY },
        });
        
            const filteredData = response.data.filter((event) => {
            const eventDate = DateTime.fromISO(event.execution_date);
            const startDate = DateTime.fromISO(this.initialDate);
            const endDate = DateTime.fromISO(this.finalDate);

            return eventDate >= startDate && eventDate <= endDate;
          });

          if (filteredData.length > 0) {
            this.processData(filteredData);
          } else {
            this.clearData();
          }
      
        } 
       catch (error) {
        console.log(error)
        
        this.clearData();
      } finally {
        this.isLoading = false;
      }
    },

    processData(data) {
      this.events = data.map((row) => ({
        user: row.user,
        name: row.name,
        execution_date: this.formatDate(row.execution_date),
      }));
      this.totalRows = data.length;
    },

    formatDate(value) {
      if (!value) return "";
      const date = new Date(value);
      if (isNaN(date.getTime())) return value;

      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = date.getUTCFullYear();

      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },

    clearData() {
      this.events = [];
      this.totalRows = 0;
    },
  },
};
</script>
